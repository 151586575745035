import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import ProfileVerificationWizard from "./ProfileVerificationWizard.tsx";
import clsx from "clsx";
import {useNavigate} from "react-router-dom";

function RequestProfileVerification({status}) {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(false)
  return (
    <>
      <div className={clsx('alert d-flex flex-column flex-sm-row p-2 mb-0 justify-content-center mb-5', {'bg-warning': status != 'PENDING', 'bg-info': status == 'PENDING'})} style={{borderRadius: 0}}>
        <div className="d-flex flex-column text-light pe-0 pe-sm-10">
        <span
          className="fs-3 fw-semibold">{status == 'PENDING' ? t('Message_account_in_pending') : t('Message_account_in_dev_mode')}</span>
        </div>

        <a onClick={() => {
          if (status == 'PENDING') {
            navigate('/account/profile');
            return;
          }
          setShowModal(true);
        }}
           className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-sm btn-light ms-sm-auto request-verification">
          {status == 'PENDING' ? t('Update') : t('Verify Now')}
        </a>
      </div>

      <ProfileVerificationWizard show={showModal} handleClose={() => setShowModal(false)} />
    </>
  );
}

export default RequestProfileVerification;
